import { boxShadows, colors } from '@/libs/styles'
import { VStack } from '@chakra-ui/react'
import { VFC } from 'react'
import { AppLogo } from '../atoms/app-logo.atom'
import { LoginForm } from '../organisms/login-form'
import { useResponsiveStylingObject } from '@/utils/style.util'

type LoginTemplate = {
  onLoginButtonClicked: (
    username: string,
    password: string
  ) => Promise<void> | void
}

export const LoginTemplate: VFC<LoginTemplate> = ({ onLoginButtonClicked }) => {
  return (
    <VStack
      backgroundColor={colors.white[7]}
      py="32px"
      px={useResponsiveStylingObject<string>({
        mobile: '32px',
        desktop: '64px',
      })}
      w={useResponsiveStylingObject<string>({
        mobile: '100%',
        desktop: 'auto',
      })}
      boxShadow={useResponsiveStylingObject<string>({
        mobile: boxShadows.none,
        desktop: boxShadows.desktop.card.normal,
      })}
      borderRadius={useResponsiveStylingObject<string>({
        mobile: '0px',
        desktop: '8px',
      })}
    >
      <VStack
        w="100%"
        spacing={useResponsiveStylingObject<string>({
          mobile: '32px',
          desktop: '10px',
        })}
      >
        <AppLogo
          imageBoxProps={{
            w: useResponsiveStylingObject<string>({
              mobile: '139px',
              desktop: '225px',
            }),
          }}
        ></AppLogo>
        <LoginForm onSubmit={onLoginButtonClicked} w="100%"></LoginForm>
      </VStack>
    </VStack>
  )
}
