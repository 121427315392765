import { useLocale } from '@/hooks/common'
import { colors, fontSizes } from '@/libs/styles'
import { Box, BoxProps, Text, TextProps, VStack } from '@chakra-ui/react'
import Image from 'next/image'
import { VFC } from 'react'
import { useResponsiveStylingObject } from '@/utils/style.util'

type AppLogoProps = {
  imageBoxProps?: BoxProps
  textProps?: TextProps
}

export const AppLogo: VFC<AppLogoProps> = ({ imageBoxProps, textProps }) => {
  const {
    texts: { app },
  } = useLocale()
  return (
    <VStack>
      <Box {...imageBoxProps}>
        <Image
          src="/images/logos/logo.png"
          width={205}
          height={56}
          layout="responsive"
          alt="atinn logo"
        />
      </Box>
      <Text
        {...{
          textColor: colors.black[7],
          fontWeight: 'bold',
          fontSize: useResponsiveStylingObject<string>({
            mobile: fontSizes.h6,
            desktop: fontSizes.h4,
          }),
          ...textProps,
        }}
      >
        {app.title}
      </Text>
    </VStack>
  )
}
