import type { NextPage } from 'next'
import Head from 'next/head'
import { useEffect } from 'react'
import { usePageContext } from '@/stores/contexts/page.context'
import { LoginPage } from '@/features/auth/pages/login.page'
import { useLayoutContext } from '@/stores/contexts/layout.context'

const Home: NextPage = () => {
  const pageContext = usePageContext()
  const layoutContext = useLayoutContext()

  useEffect(() => {
    pageContext.changeTitle('ログイン')
    layoutContext.setMenuStatus(undefined)
  })

  return (
    <div>
      <Head>
        <title>ログイン -清掃管理システム-</title>
      </Head>

      <LoginPage></LoginPage>
    </div>
  )
}

export default Home
