import { Button } from '@/components/atoms/forms/button.atom'
import { useLocale, useYupValidationResolver } from '@/hooks/common'
import { useToast } from '@/hooks/toast/use-toast.hook'
import { Unicons } from '@/libs/icons/unicons'
import { Box, BoxProps, HStack, VStack } from '@chakra-ui/react'
import { css } from '@emotion/react'
import { useEffect, VFC } from 'react'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { useResponsiveStylingObject } from '@/utils/style.util'
import { ControlledTextInput } from '@/components/atoms/forms/react-hook-form/controlled-text-input.atom'
import { yup } from '@/libs/yup'
import { ControlledPasswordInput } from '@/components/atoms/forms/react-hook-form/controlled-password-input.atom'

type LoginFormProps = Omit<BoxProps, 'onSubmit'> & {
  onSubmit: (username: string, password: string) => Promise<void> | void
}

type LoginFormInput = {
  username: string
  password: string
}

export const LoginForm: VFC<LoginFormProps> = ({ onSubmit, ...boxProps }) => {
  const toast = useToast()
  const {
    texts: {
      auth: {
        login: { form: formTexts },
      },
      common: { form: commonFormTexts },
    },
  } = useLocale()

  const resolver = useYupValidationResolver({
    validationSchema: yup.object({
      username: yup.string().required(commonFormTexts.requiredText),
      password: yup.string().required(commonFormTexts.requiredText),
    }),
  })
  const formMethods = useForm<LoginFormInput>({
    mode: 'onChange',
    resolver,
  })
  const { control, handleSubmit, formState, resetField, watch, setFocus } =
    formMethods

  const submitHandler: SubmitHandler<LoginFormInput> = async (data) => {
    if (
      !formState.isValid ||
      formState.isValidating ||
      formState.isSubmitting
    ) {
      return
    }
    try {
      await onSubmit(data.username, data.password)
      toast({
        title: formTexts.toast.success.title,
        description: formTexts.toast.success.description(watch().username),
        status: 'success',
      })
    } catch {
      toast({
        title: formTexts.toast.failure.title,
        description: formTexts.toast.failure.description,
        status: 'error',
      })
      resetField('password')
      setFocus('password')
    }
  }

  useEffect(() => {
    setFocus('username')
  }, [])

  return (
    <Box {...boxProps}>
      <FormProvider {...formMethods}>
        <form
          onSubmit={handleSubmit(submitHandler)}
          css={css`
            width: 100%;
          `}
        >
          <VStack
            spacing={useResponsiveStylingObject<string>({
              mobile: '16px',
              desktop: '24px',
            })}
            w="100%"
          >
            <VStack
              spacing={useResponsiveStylingObject<string>({
                mobile: '8px',
                desktop: '16px',
              })}
              w="100%"
            >
              {/* ログインID */}
              <ControlledTextInput
                name="username"
                defaultValue=""
                control={control}
                label={{ text: formTexts.input.username.label }}
                placeholder={formTexts.input.username.placeholder}
              />

              {/* パスワード */}
              <ControlledPasswordInput
                control={control}
                name="password"
                defaultValue=""
                label={{ text: formTexts.input.password.label }}
                placeholder={formTexts.input.password.placeholder}
              />
            </VStack>

            <HStack justifyContent="flex-end" w="100%">
              {/* ログインボタン */}
              <Button
                leftIcon={Unicons.UilSignInAlt}
                type="submit"
                disabled={!formState.isValid || formState.isSubmitting}
                isLoading={formState.isSubmitting}
              >
                {formTexts.control.submit.text}
              </Button>
            </HStack>
          </VStack>
        </form>
      </FormProvider>
    </Box>
  )
}
