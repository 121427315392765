import { colors } from '@/libs/styles'
import { Unicons } from '@/libs/icons/unicons'
import { forwardRef, useBreakpointValue } from '@chakra-ui/react'
import { useState, VFC } from 'react'
import { TextInput, TextInputProps } from './text-input.atom'
import { useResponsiveStylingObject } from '@/utils/style.util'

export type PasswordInputProps = Omit<TextInputProps, 'type'> & {}

const EyeIcon: VFC<{ open: boolean; onClick: any }> = ({
  open = false,
  onClick = () => {},
}) => {
  const Icon = open ? Unicons.UilEyeSlash : Unicons.UilEye
  const size = useBreakpointValue(
    useResponsiveStylingObject<number>({ mobile: 24, desktop: 30 })
  )
  return (
    <button type="button" onClick={() => onClick()}>
      <Icon size={size} color={colors.main[1]} />
    </button>
  )
}

export const PasswordInput = forwardRef<PasswordInputProps, 'input'>(
  (props, ref) => {
    const [show, setShow] = useState(false)

    return (
      <TextInput
        {...props}
        type={show ? 'text' : 'password'}
        ref={ref}
        rightElement={
          <EyeIcon open={show} onClick={() => setShow(!show)}></EyeIcon>
        }
      ></TextInput>
    )
  }
)
