import {
  PasswordInput,
  PasswordInputProps,
} from '@/components/atoms/forms/password-input.atom'
import { useController, FieldValues, UseControllerProps } from 'react-hook-form'
import {
  TextInputDecorator,
  TextInputDecoratorProps,
} from '@/components/molecules/forms/text-input-decorator.molecules'

type ControlledPasswordInputProps<T extends FieldValues> = Omit<
  PasswordInputProps,
  'value' | 'onChange'
> &
  UseControllerProps<T> & {
    label?: TextInputDecoratorProps['label']
  }

export const ControlledPasswordInput = <T extends FieldValues>({
  name,
  control,
  label,
  defaultValue,
  ...passwordInputProps
}: ControlledPasswordInputProps<T>) => {
  const { field, fieldState } = useController<T>({
    name,
    control,
    defaultValue,
  })

  return (
    <TextInputDecorator
      label={label}
      extension={{ errors: [fieldState.error?.message ?? ''] }}
      w="100%"
    >
      <PasswordInput {...field} {...passwordInputProps} />
    </TextInputDecorator>
  )
}
